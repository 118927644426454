import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  UserInfoState,
  UserInfoInitialState,
  OtpInitialState,
  OtpState,
  CreateUserOtpInitialState,
  CreateUserOtpState,
  DeleteUserInitialState,
  DeleteUserState,
  DailyUsersInitialState,
  DailyUsersState,
} from "../state/authState";

const userLoginWithPasswordSlice = createSlice({
  name: "loginWithPassword",
  initialState: UserInfoInitialState,
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
      state.user_info = undefined;
    },
    loginSuccess: (state, action: PayloadAction<UserInfoState>) => {
      state.loading = action.payload.loading;
      state.success = action.payload.success;
      state.user_info = action.payload.user_info;
      
    },
    loginFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

const logOutSlice = createSlice({
  name: "logOutSlice",
  initialState: {loading: false, success: undefined as boolean | undefined, error: null as string | null},
  reducers: {
    logoutRequest: (state) => {
      state.loading = true;
    },
    logOutSuccess: (state) => {
      state.error = null;
      state.success = true;
      state.loading = false;
      
    },
    logOutFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    logOutReset: (state, action: PayloadAction<any>) => {
      state.loading = action.payload.loading;
      state.success = action.payload.success;
      state.error = action.payload.error;
    },
  }
})

const sendOtpSlice = createSlice({
  name: "sendOtp",
  initialState: OtpInitialState,
  reducers: {
    sendOtpRequest: (state) => {
      state.loading = true;
      state.detail = null;
    },
    sendOtpSuccess: (state, action: PayloadAction<OtpState>) => {
      state.loading = false;
      state.success = true;
      state.otpSuccess = true;
      state.detail = action.payload.detail;
    },
    sendOtpFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    sendOtpUserNotFound: (state, action: PayloadAction<OtpState>) => {
      state.loading = false;
      state.success = true;
      state.otpSuccess = false;
      state.detail = action.payload.detail;
    },
    sendOtpReset: (state) => {
      state.detail = OtpInitialState.detail;
      state.loading = OtpInitialState.loading;
      state.success = false;
      state.otpSuccess = false;
      state.error = OtpInitialState.error;
    },
  },
});

const loginWithOtpSlice = createSlice({
  name: "loginWithOtp",
  initialState: UserInfoInitialState,
  reducers: {
    loginOtpRequest: (state) => {
      state.loading = true;
      state.user_info = undefined;
    },
    loginOtpSuccess: (state, action: PayloadAction<UserInfoState>) => {
      state.loading = false;
      state.success = true;
      state.user_info = action.payload.user_info;
    },
    loginOtpFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    loginOtpReset: (state) => {
      state.user_info = undefined;
      state.loading = false;
      state.success = false;
      state.error = null;
    },
  },
});

const userRegistrationSlice = createSlice({
  name: "userRegistrationSlice",
  initialState: CreateUserOtpInitialState,
  reducers: {
    createUserOtpRequest: (state) => {
      state.loading = true;
      state.detail = null;
    },
    createUserOtpSuccess: (
      state,
      action: PayloadAction<CreateUserOtpState>
    ) => {
      state.loading = false;
      state.success = true;
      state.detail = action.payload.detail;
    },
    createUserOtpFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    createUserOtpReset: (state) => {
      state.detail = null;
      state.loading = false;
      state.success = false;
      state.error = null;
    },
  },
});

const changeUserPasswordSlice = createSlice({
  name: "changeUserPassword",
  initialState: UserInfoInitialState,
  reducers: {
    changeUserPasswordRequest: (state) => {
      state.loading = true;
      state.user_info = undefined;
    },
    changeUserPasswordSuccess: (
      state,
      action: PayloadAction<UserInfoState>
    ) => {
      state.loading = false;
      state.success = true;
      state.user_info = action.payload.user_info;
    },
    changeUserPasswordFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    changeUserPasswordReset: (state) => {
      state.user_info = undefined;
      state.loading = false;
      state.success = false;
      state.error = null;
    },
  },
});

const updateUserSlice = createSlice({
  name: "updateUser",
  initialState: UserInfoInitialState,
  reducers: {
    updateUserRequest: (state) => {
      state.loading = true;
      state.user_info = undefined;
    },
    updateUserSuccess: (state, action: PayloadAction<UserInfoState>) => {
      state.loading = false;
      state.success = true;
      state.user_info = action.payload.user_info;
    },
    updateUserFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    updateUserReset: (state) => {
      state.user_info = undefined;
      state.loading = false;
      state.success = false;
      state.error = null;
    },
  },
});

const delteUserSlice = createSlice({
  name: "delteUserSlice",
  initialState: DeleteUserInitialState,
  reducers: {
    deleteUserRequest: (state) => {
      state.loading = true;
      state.detail = null;
    },
    deleteUserSuccess: (state, action: PayloadAction<DeleteUserState>) => {
      state.loading = false;
      state.success = true;
      state.detail = action.payload.detail;
    },
    deleteUserFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    deleteUserReset: (state) => {
      state.detail = null;
      state.loading = false;
      state.success = false;
      state.error = null;
    },
  },
});

const dailyUsersVisitSlice = createSlice({
  name: "dailyUsersVisit",
  initialState: DailyUsersInitialState,
  reducers: {
    dailyUsersVisitRequest: (state) => {
      state.loading = true;
      state.results = undefined;
    },
    dailyUsersVisitSuccess: (state, action: PayloadAction<DailyUsersState>) => {
      state.loading = false;
      state.success = true;
      state.results = action.payload.results;
    },
    dailyUsersVisitFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    dailyUsersVisitReset: (state) => {
      state.results = undefined;
      state.loading = false;
      state.success = false;
      state.error = null;
    },
  },
});

const addUserAddressSlice = createSlice({
  name: "addUserAddress",
  initialState: UserInfoInitialState,
  reducers: {
    addUserAddressRequest: (state) => {
      state.loading = true;
      state.user_info = undefined;
    },
    addUserAddressSuccess: (state, action: PayloadAction<UserInfoState>) => {
      state.loading = false;
      state.success = true;
      state.user_info = action.payload.user_info;
    },
    addUserAddressFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    addUserAddressReset: (state) => {
      state.loading = false
      state.user_info = undefined
      state.success = false
    },
  },
});

const updateUserAddressSlice = createSlice({
  name: "updateUserAddress",
  initialState: UserInfoInitialState,
  reducers: {
    updateUserAddressRequest: (state) => {
      state.loading = true;
      state.user_info = undefined;
    },
    updateUserAddressSuccess: (state, action: PayloadAction<UserInfoState>) => {
      state.loading = false;
      state.success = true;
      state.user_info = action.payload.user_info;
    },
    updateUserAddressFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    updateUserAddressReset: (state) => {
      state.loading = false
      state.user_info = undefined
      state.success = false
    },
  },
});

const deleteUserAddressSlice = createSlice({
  name: "updateUserAddress",
  initialState: UserInfoInitialState,
  reducers: {
    deleteUserAddressRequest: (state) => {
      state.loading = true;
      state.user_info = undefined;
    },
    deleteUserAddressSuccess: (state, action: PayloadAction<UserInfoState>) => {
      state.loading = false;
      state.success = true;
      state.user_info = action.payload.user_info;
    },
    deleteUserAddressFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    deleteUserAddressReset: (state) => {
      state.loading = false
      state.user_info = undefined
      state.success = false
    },
  },
});

export const { loginRequest, loginFailure, loginSuccess } =
  userLoginWithPasswordSlice.actions;
export const userLoginReducer = userLoginWithPasswordSlice.reducer;

export const { logOutSuccess, logoutRequest, logOutFailure, logOutReset } =
  logOutSlice.actions;
export const UserLogOutReducer = logOutSlice.reducer;

export const {
  sendOtpRequest,
  sendOtpSuccess,
  sendOtpUserNotFound,
  sendOtpFailure,
  sendOtpReset,
} = sendOtpSlice.actions;
export const sendOtpReducer = sendOtpSlice.reducer;

export const {
  loginOtpRequest,
  loginOtpSuccess,
  loginOtpFailure,
  loginOtpReset,
} = loginWithOtpSlice.actions;
export const loginOtpReducer = loginWithOtpSlice.reducer;

export const {
  createUserOtpFailure,
  createUserOtpReset,
  createUserOtpRequest,
  createUserOtpSuccess,
} = userRegistrationSlice.actions;
export const OtpUserRegistrationReducer = userRegistrationSlice.reducer;

export const {
  deleteUserFailure,
  deleteUserRequest,
  deleteUserReset,
  deleteUserSuccess,
} = delteUserSlice.actions;
export const deleteUserReducer = delteUserSlice.reducer;

export const {
  changeUserPasswordRequest,
  changeUserPasswordSuccess,
  changeUserPasswordFailure,
  changeUserPasswordReset,
} = changeUserPasswordSlice.actions;

export const changeUserPasswordReducer = changeUserPasswordSlice.reducer;

export const {
  updateUserFailure,
  updateUserRequest,
  updateUserReset,
  updateUserSuccess,
} = updateUserSlice.actions;
export const updateUserReducer = updateUserSlice.reducer;

export const {
  dailyUsersVisitFailure,
  dailyUsersVisitRequest,
  dailyUsersVisitReset,
  dailyUsersVisitSuccess,
} = dailyUsersVisitSlice.actions;
export const dailyUsersVisitReducer = dailyUsersVisitSlice.reducer;

export const {
  addUserAddressFailure,
  addUserAddressRequest,
  addUserAddressSuccess,
  addUserAddressReset,
} = addUserAddressSlice.actions;
export const addUserAddressReducer = addUserAddressSlice.reducer;

export const {
  updateUserAddressFailure,
  updateUserAddressRequest,
  updateUserAddressSuccess,
  updateUserAddressReset,
} = updateUserAddressSlice.actions;
export const updateUserAddressReducer = updateUserAddressSlice.reducer;

export const {
  deleteUserAddressFailure,
  deleteUserAddressRequest,
  deleteUserAddressSuccess,
  deleteUserAddressReset
} = deleteUserAddressSlice.actions;
export const deleteUserAddressReducer = deleteUserAddressSlice.reducer;
