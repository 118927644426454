import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    LinkInitialState,
    LinkState,
    MetaInitialState,
    MetaState,
    MetaNewState,
    MetaNewInitialState,
    AppReviewState,
    AppReviewInitialState
} from "../state/seoState";


const metaSlice = createSlice({
    name: "metas",
    initialState: MetaInitialState,
    reducers: {
        metasRequest: (state) => {
            state.loading = true;
            state.result = undefined;
        },
        metasSuccess: (
            state,
            action: PayloadAction<MetaState>
        ) => {
            state.loading = false;
            state.success = true;
            state.result = action.payload.result;
        },
        metasFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        },
    },
});

const metaNewSlice = createSlice({
    name: "metas_new",
    initialState: MetaNewInitialState,
    reducers: {
        metasNewRequest: (state) => {
            state.loading = true;
            state.result = undefined;
        },
        metasNewSuccess: (
            state,
            action: PayloadAction<MetaNewState>
        ) => {
            state.loading = false;
            state.success = true;
            state.result = action.payload.result;
        },
        metasNewFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        },
    },
});

const linkSlice = createSlice({
    name: "link",
    initialState: LinkInitialState,
    reducers: {
        linkRequest: (state) => {
            state.loading = true;
            state.result = undefined;
        },
        linkSuccess: (
            state,
            action: PayloadAction<LinkState>
        ) => {
            state.loading = false;
            state.success = true;
            state.result = action.payload.result;
        },
        linkFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        },
    },
});


const reviewSlice = createSlice({
    name: "review",
    initialState: AppReviewInitialState,
    reducers: {
        reviewRequest: (state) => {
            state.loading = true;
            state.result = undefined;
        },
        reviewSuccess: (
            state,
            action: PayloadAction<AppReviewState>
        ) => {
            state.loading = false;
            state.success = true;
            state.result = action.payload.result;
        },
        reviewFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
            state.success = false;
        },
    },
});


export const { linkRequest, linkSuccess, linkFailure } =
    linkSlice.actions;
export const LinkReducer = linkSlice.reducer;
export const { metasRequest, metasSuccess, metasFailure } =
    metaSlice.actions;

export const MetaListReducer = metaSlice.reducer;

export const { metasNewRequest, metasNewSuccess, metasNewFailure } =
    metaNewSlice.actions;

export const MetaNewReducer = metaNewSlice.reducer

export const { reviewRequest, reviewSuccess, reviewFailure } = reviewSlice.actions;
export const ReviewReducer = reviewSlice.reducer;