import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  loginRequest,
  loginFailure,
  loginSuccess,
  sendOtpFailure,
  sendOtpRequest,
  sendOtpSuccess,
  sendOtpUserNotFound,
  loginOtpRequest,
  loginOtpSuccess,
  loginOtpFailure,
  logOutSuccess,
  logOutFailure,
  logoutRequest,
  createUserOtpFailure,
  createUserOtpSuccess,
  createUserOtpRequest,
  deleteUserFailure,
  deleteUserRequest,
  deleteUserSuccess,
  changeUserPasswordFailure,
  changeUserPasswordRequest,
  changeUserPasswordSuccess,
  updateUserFailure,
  updateUserRequest,
  updateUserSuccess,
  dailyUsersVisitFailure,
  dailyUsersVisitRequest,
  dailyUsersVisitSuccess,
  addUserAddressFailure,
  addUserAddressRequest,
  addUserAddressSuccess,
  updateUserAddressFailure,
  updateUserAddressRequest,
  updateUserAddressSuccess,
  deleteUserAddressFailure,
  deleteUserAddressRequest,
  deleteUserAddressSuccess,
} from "../features/accountSlice";
import { saveAuthData } from "./auth";

interface CustomError extends Error {
  response?: {
    data: {
      detail: string;
    };
  };
}

interface AddUserAddressParams {
  user_id: number;
  name: string;
  street: string;
  landmark: string;
  state: string;
  city: string;
}

interface UpdateUserAddressParams {
  id: number;
  user_id: number;
  name: string;
  street: string;
  landmark: string;
  state: string;
  city: string;
}

interface DeleteUserAddressParams {
  id: number;
  user_id: number;
}

interface userParams {
  phone_number: string;
  password: number;
}
interface loginOtpParams {
  phone_number: string;
  otp: number;
}

interface userRegistrationProps {
  phone_number: string;
  name: string;
  email: string;
}

interface DeleteUserParams {
  phone_number: string;
  reason: string;
}

interface ChangeUserPasswordParams {
  phone_number: string;
  password: string;
}

interface UpdateUserParams {
  phone_number: string;
  profile_image: File | null;
  name: string;
  email: string;
}

export const loginWithPasswordAction = createAsyncThunk(
  "loginWithPassword",
  async (params: userParams, thunkAPI) => {
    console.log(params);
    
    try {
      thunkAPI.dispatch(loginRequest());
      const { data } = await axios.post(`/api/v1/accounts/login/`, {
        phone_number: params.phone_number,
        password: params.password,
      });
      thunkAPI.dispatch(loginSuccess({user_info: data.user_info, error: null, loading: false, success: true}));
      saveAuthData(data.user_info)
      // localStorage.setItem("userInfo", JSON.stringify(data.user_info));
    } catch (error) {
      const customError = error as CustomError;

      thunkAPI.dispatch(
        loginFailure(
          customError.response && customError.response.data.detail
            ? customError.response.data.detail
            : customError.message
        )
      );
    }
  }
);


export const logOutAction = createAsyncThunk(
  "logOut",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(logoutRequest());
      const { data } = await axios.post(`/api/v1/accounts/logout/`);
      thunkAPI.dispatch(logOutSuccess(data));
      thunkAPI.dispatch(loginSuccess({user_info: undefined, error: null, loading: false, success: undefined}));
    } catch (error) {
      const customError = error as CustomError;

      thunkAPI.dispatch(
        logOutFailure(
          customError.response && customError.response.data.detail
            ? customError.response.data.detail
            : customError.message
        )
      );
    }
  }
);

export const sendOtpToMobileAction = createAsyncThunk(
  "sendOtptoMobile",
  async (phone_number: string, thunkAPI) => {
    try {
      thunkAPI.dispatch(sendOtpRequest());
      const { data } = await axios.post(
        `/api/v1/accounts/send_otp_to_mobile/${phone_number}/`
      );
      if (
        data.detail === "number is not registered, initialte the registration"
      ) {
        thunkAPI.dispatch(sendOtpUserNotFound(data));
      } else {
        thunkAPI.dispatch(sendOtpSuccess(data));
      }
    } catch (error) {
      const customError = error as CustomError;

      thunkAPI.dispatch(
        sendOtpFailure(
          customError.response && customError.response.data.detail
            ? customError.response.data.detail
            : customError.message
        )
      );
    }
  }
);

export const loginWithOtpAction = createAsyncThunk(
  "loginWithOtp",
  async (params: loginOtpParams, thunkAPI) => {
    try {
      thunkAPI.dispatch(loginOtpRequest());
      const { data } = await axios.post(
        `/api/v1/accounts/login_with_otp/${params.phone_number}/${params.otp}/`
      );
      thunkAPI.dispatch(loginOtpSuccess(data));
      thunkAPI.dispatch(loginSuccess({user_info: data.user_info, error: null, loading: false, success: true}));
      saveAuthData(data.user_info)
      // localStorage.setItem("userInfo", JSON.stringify(data.user_info));
    } catch (error) {
      const customError = error as CustomError;

      thunkAPI.dispatch(
        loginOtpFailure(
          customError.response && customError.response.data.detail
            ? customError.response.data.detail
            : customError.message
        )
      );
    }
  }
);

export const userRegistrationWithOtp = createAsyncThunk(
  "userRegistrationWithOtp",
  async (params: userRegistrationProps, thunkAPI) => {
    try {
      thunkAPI.dispatch(createUserOtpRequest());
      const { data } = await axios.post(
        `/api/v1/accounts/create_user_send_otp/`,
        {
          phone_number: params.phone_number,
          name: params.name,
          email: params.email,
        }
      );
      thunkAPI.dispatch(createUserOtpSuccess(data));
    } catch (error) {
      const customError = error as CustomError;

      thunkAPI.dispatch(
        createUserOtpFailure(
          customError.response && customError.response.data.detail
            ? customError.response.data.detail
            : customError.message
        )
      );
    }
  }
);

export const changeUserPasswordAction = createAsyncThunk(
  "changeUserPassword",
  async (params: ChangeUserPasswordParams, thunkAPI) => {
    try {
      thunkAPI.dispatch(changeUserPasswordRequest());

      const {
        userLogin: { user_info },
      }: any = thunkAPI.getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user_info.access}`,
        },
      };
      const { data } = await axios.patch(
        `/api/v1/accounts/change_password/${params.phone_number}/`,
        {
          password: params.password,
        },
        config
      );
      thunkAPI.dispatch(loginSuccess({user_info: data.user_info, error: null, loading: false, success: true}));
      thunkAPI.dispatch(changeUserPasswordSuccess(data));
    } catch (error) {
      const customError = error as CustomError;

      thunkAPI.dispatch(
        changeUserPasswordFailure(
          customError.response && customError.response.data.detail
            ? customError.response.data.detail
            : customError.message
        )
      );
    }
  }
);

export const updateUserAction = createAsyncThunk(
  "updateUser",
  async (params: UpdateUserParams, thunkAPI) => {
    console.log(params.profile_image);

    try {
      thunkAPI.dispatch(updateUserRequest());

      const {
        userLogin: { user_info },
      }: any = thunkAPI.getState();

      const config = {
        headers: {
          "Content-type": "multipart/form-data",
          // "Content-type": "application/json",
          Authorization: `Bearer ${user_info.access}`,
        },
      };
      const { data } = await axios.put(
        `/api/v1/accounts/name_email_address_update/${params.phone_number}/`,
        {
          email: params.email,
          profile_image: params.profile_image,
          name: params.name,
        },
        config
      );
      thunkAPI.dispatch(loginSuccess({user_info: data.user_info, error: null, loading: false, success: true}));
      thunkAPI.dispatch(updateUserSuccess(data));
      saveAuthData(data.user_info)

      // localStorage.setItem("userInfo", JSON.stringify(data.user_info));
    } catch (error) {
      const customError = error as CustomError;

      thunkAPI.dispatch(
        updateUserFailure(
          customError.response && customError.response.data.detail
            ? customError.response.data.detail
            : customError.message
        )
      );
    }
  }
);

export const deleteUserAction = createAsyncThunk(
  "deleteUser",
  async (params: DeleteUserParams, thunkAPI) => {
    try {
      thunkAPI.dispatch(deleteUserRequest());
      const {
        userLogin: { user_info },
      }: any = thunkAPI.getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user_info.access}`,
        },
      };
      const { data } = await axios.post(
        `/api/v1/accounts/delete_user/${params.phone_number}/`,
        {
          reason: params.reason,
        },
        config
      );
      thunkAPI.dispatch(logOutAction());
      thunkAPI.dispatch(deleteUserSuccess(data));
    } catch (error) {
      const customError = error as CustomError;

      thunkAPI.dispatch(
        deleteUserFailure(
          customError.response && customError.response.data.detail
            ? customError.response.data.detail
            : customError.message
        )
      );
    }
  }
);

export const addUserAddressAction = createAsyncThunk(
  "addUserAddress",
  async (params: AddUserAddressParams, thunkAPI) => {
    try {
      thunkAPI.dispatch(addUserAddressRequest());
      const {
        userLogin: { user_info },
      }: any = thunkAPI.getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user_info.access}`,
        },
      };
      const { data } = await axios.post(
        `/api/v1/accounts/add_user_address/${params.user_id}/`,
        {
          name: params.name,
          street: params.street,
          landmark: params.landmark,
          city: params.city,
          state: params.state,
        },
        config
      );
      thunkAPI.dispatch(loginSuccess({user_info: data.user_info, error: null, loading: false, success: true}));
      thunkAPI.dispatch(addUserAddressSuccess(data));
      saveAuthData(data.user_info)

      // localStorage.setItem("userInfo", JSON.stringify(data.user_info));
    } catch (error) {
      const customError = error as CustomError;

      thunkAPI.dispatch(
        addUserAddressFailure(
          customError.response && customError.response.data.detail
            ? customError.response.data.detail
            : customError.message
        )
      );
    }
  }
);

export const updateUserAddressAction = createAsyncThunk(
  "updateUserAddress",
  async (params: UpdateUserAddressParams, thunkAPI) => {
    try {
      thunkAPI.dispatch(updateUserAddressRequest());
      const {
        userLogin: { user_info },
      }: any = thunkAPI.getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user_info.access}`,
        },
      };
      const { data } = await axios.post(
        `/api/v1/accounts/update_user_address/${params.id}/${params.user_id}/`,
        {
          name: params.name,
          street: params.street,
          landmark: params.landmark,
          city: params.city,
          state: params.state,
        },
        config
      );
      thunkAPI.dispatch(loginSuccess({user_info: data.user_info, error: null, loading: false, success: true}));
      thunkAPI.dispatch(updateUserAddressSuccess(data));
      saveAuthData(data.user_info)

      // localStorage.setItem("userInfo", JSON.stringify(data.user_info));
    } catch (error) {
      const customError = error as CustomError;

      thunkAPI.dispatch(
        updateUserAddressFailure(
          customError.response && customError.response.data.detail
            ? customError.response.data.detail
            : customError.message
        )
      );
    }
  }
);

export const deleteUserAddressAction = createAsyncThunk(
  "deleteUserAddress",
  async (params: DeleteUserAddressParams, thunkAPI) => {
    try {
      thunkAPI.dispatch(deleteUserAddressRequest());
      const {
        userLogin: { user_info },
      }: any = thunkAPI.getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user_info.access}`,
        },
      };
      const { data } = await axios.delete(
        `/api/v1/accounts/delete_user_address/${params.id}/${params.user_id}/`,
        config
      );
      thunkAPI.dispatch(loginSuccess({user_info: data.user_info, error: null, loading: false, success: true}));
      thunkAPI.dispatch(deleteUserAddressSuccess(data));
      saveAuthData(data.user_info)

      // localStorage.setItem("userInfo", JSON.stringify(data.user_info));
    } catch (error) {
      const customError = error as CustomError;

      thunkAPI.dispatch(
        deleteUserAddressFailure(
          customError.response && customError.response.data.detail
            ? customError.response.data.detail
            : customError.message
        )
      );
    }
  }
);

export const dailyUsersVisitAction = createAsyncThunk(
  "dailyUsersVisit",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(dailyUsersVisitRequest());
      const { data } = await axios.get(`/api/v1/accounts/daily_users_visit/`);
      thunkAPI.dispatch(dailyUsersVisitSuccess(data));
    } catch (error) {
      const customError = error as CustomError;

      thunkAPI.dispatch(
        dailyUsersVisitFailure(
          customError.response && customError.response.data.detail
            ? customError.response.data.detail
            : customError.message
        )
      );
    }
  }
);
