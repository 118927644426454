import React from "react";
import { Spinner, Input } from "reactstrap";
import { Controller } from "react-hook-form";
import { MouseEvent, ChangeEvent } from "react";
import { Control, FieldErrors, UseFormHandleSubmit } from "react-hook-form";
import "./loginmodal.scss";

interface SignUpProps {
    switchToAuth: (choice: "login" | "signup" | "loginWithOtp") => void;
    handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void,
    loginForm: {
        phone_number: string,
        password: string,
        otp: string,
    };
    loginOtpFormHandler: (e: MouseEvent<HTMLButtonElement>) => void;
    userRegistrationHandleSubmit: (data: any) => void;
 handleSubmit: UseFormHandleSubmit<any>;
    control: Control<any>;
    errors: FieldErrors<any>;
    signUpProps: {
      isUserSuccess: boolean | undefined,
      isUserDetail: string | null,
      detail: string | null, 
      isOtpLoading: boolean,
      isUserError: string | null, 
      isUserLoading: boolean
    }
}

const SignUpModal: React.FC<SignUpProps> = ({ 
  switchToAuth,
  loginForm,
  handleInputChange,
  signUpProps,
  errors,
  handleSubmit,
  userRegistrationHandleSubmit,
  loginOtpFormHandler,
  control 
}) => {
    const { isUserDetail, isUserError, isOtpLoading, isUserSuccess, detail, isUserLoading} = signUpProps

    

    return (
          <>
            <div className="overlay-container none-mob">
              <div className="overlay">
                <div className="overlay-panel overlay-right">
                  <img
                    className="img-log"
                    src="https://buybackart-s3-prod-bucket.s3.ap-south-1.amazonaws.com/static/userLogin/sigup.png"
                    alt="None"
                  />

                  <button
                    className="ghost bttn-log"
                    id="signIn"
                    onClick={() => switchToAuth("login")}
                  >
                    Sign In
                  </button>
                  <p> or</p>
                  <button
                    className="ghost bttn-log mt-1"
                    onClick={() => switchToAuth("loginWithOtp")}
                  >
                    Login with OTP
                  </button>
                </div>
              </div>
            </div>
            <div className="form-container sign-in-container">
              <form className="faram">
                <h3 className="heading-log">Create Account</h3>
                <div className="social-container">
                  <a href="#" className="social ahref">
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a href="#" className="social ahref">
                    <i className="fab fa-google-plus-g" />
                  </a>
                </div>
                {/* <span>or use your email for registration</span> */}
                {isUserSuccess ? (
                  <>
                    <h1 className="heading-log">Sign in With OTP</h1>
                    <input
                      required
                      type="password"
                      name="otp"
                      placeholder="OTP"
                      className="inp"
                      value={loginForm.otp}
                      onChange={handleInputChange}
                    />
                    {isUserDetail ? (
                      <div>{isUserDetail}</div>
                    ) : (
                      <div>{detail}</div>
                    )}
                    <button
                      className={`sign-up-button bttn mb-1 ${isOtpLoading ? "dimmed-button" : ""
                        }`}
                      onClick={(e) => loginOtpFormHandler(e)}
                    >
                      {isOtpLoading && (
                        <Spinner
                          animation="border"
                          role="status"
                          size="sm"
                          className=""
                        />
                      )}
                      <span className="mx-3">Login</span>
                    </button>

                  </>
                ) : (
                  <>
                    <Controller
                      name="phone_number"
                      control={control}
                      render={({ field }) => (
                        <Input
                          className="react-select inp"
                          invalid={errors.phone_number && true}
                          type="text"
                          id="phone_number"
                          placeholder="Phone Number"
                          {...field}
                        />
                      )}
                    />

                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <Input
                          className="react-select inp"
                          invalid={errors.name && true}
                          type="text"
                          id="name"
                          placeholder="Name"
                          {...field}
                        />
                      )}
                    />

                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <Input
                          className="react-select inp"
                          invalid={errors.email && true}
                          type="email"
                          id="email"
                          placeholder="Email"
                          {...field}
                        />
                      )}
                    />
                    {isUserError && (
                      <div style={{ color: "red", fontSize: "14px" }}>
                        {isUserError}
                      </div>
                    )}
                    <button
                      className={`bttn mt-2 ${isUserLoading ? "dimmed-button" : ""
                        }`}
                      onClick={handleSubmit(userRegistrationHandleSubmit)}
                    >
                      {isUserLoading && (
                        <Spinner
                          animation="border"
                          role="status"
                          size="sm"
                          className=""
                        />
                      )}
                      <span className="mx-3">SignUp</span>
                    </button>
                    <h2>
                      <span className="none-bs">--OR--</span>
                    </h2>
                    <button
                      onClick={() => switchToAuth("loginWithOtp")}
                      className="bttn none-bs"
                    >
                      Login With OTP
                    </button>
                  </>
                )}
              </form>
            </div>
          </>
    );
};

export default SignUpModal;
