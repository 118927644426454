export type Meta = {
    id: number,
    title: string,
    description: string,
    meta: [{
        id: number,
        name: string,
    }]
}

export type AppReview = {
    id: number,
    name: string,
    app_url: string,
    app_image: string
}

export type MetaNew = {
    id: number,
    keyword: string,
    meta_description: string,
    meta_title: string,
    slug: string,
}

export type MetaState = {
    result?: Meta,
    success?: boolean,
    error: string | null,
    loading: boolean,
}

export type MetaNewState = {
    result?: MetaNew,
    success?: boolean,
    error: string | null,
    loading: boolean,
}

export type AppReviewState = {
    result?: AppReview[]
    success?: boolean,
    error: string | null,
    loading: boolean,
}

export const MetaInitialState: MetaState = {
    result: undefined,
    loading: false,
    error: null,
}

export const AppReviewInitialState: AppReviewState = {
    result: undefined,
    loading: false,
    error: null,
}

export const MetaNewInitialState: MetaNewState = {
    result: undefined,
    loading: false,
    error: null,
}

export type Link = {
    id: number,
    href: string,
    rel: string,
    slug: string,
}

export type LinkState = {
    result?: Link,
    success?: boolean,
    error: string | null,
    loading: boolean,
}

export const LinkInitialState: LinkState = {
    result: undefined,
    loading: false,
    error: null,
}